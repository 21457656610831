<template>
  <div>
    <form action="" v-if="domain">
      <section class="hero">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">Domaine</h1>
            <h2 class="subtitle">{{ domain.uuid }}</h2>
          </div>
        </div>
      </section>
      <section class="details">
        <div class="container">
          <b-field horizontal v-if="domain.has_logo" width="128">
            <img :src="logoUrl + `?${Math.floor(Math.random() * 100000)}`" alt="">
          </b-field>
          <b-field horizontal label="Slug" v-if="domain.slug">
            {{ domain.slug }}
          </b-field>

          <b-field class="file" horizontal label="Logo">
            <b-upload @input="addLogo" accept="image/png, image/jpeg">
              <a class="button is-primary">
                <b-icon icon="upload"></b-icon>
                <span>Logo</span>
              </a>
            </b-upload>
          </b-field>

          <b-field horizontal label="Modules">
            <b-checkbox v-model="domain.faq.is_activated"
              :true-value="1" :false-value="0">
                FAQ
            </b-checkbox>
            <b-checkbox v-model="domain.map.is_activated"
              :true-value="1" :false-value="0">
                Carte
            </b-checkbox>
            <b-checkbox v-model="domain.chat.is_activated"
              :true-value="1" :false-value="0">
                Chat
            </b-checkbox>
            <b-checkbox v-model="domain.news.is_activated"
              :true-value="1" :false-value="0">
                Actualités
            </b-checkbox>
            <!-- <b-checkbox v-model="domain.payment.is_activated"
              :true-value="1" :false-value="0">
                Paiement
            </b-checkbox> -->
            <b-checkbox v-model="domain.stat.is_activated"
              :true-value="1" :false-value="0">
                Stats
            </b-checkbox>
            <b-checkbox v-model="domain.survey.is_activated"
              :true-value="1" :false-value="0">
                Sondages
            </b-checkbox>
          </b-field>

          <hr>

          <h4 class="title is-5 has-text-centered" >Configuration des modules</h4>

          <div class="module">
            <h3>Faq</h3>
            <b-field horizontal label="Questions universelles" v-if="domain.faq.is_activated === 1">
              <b-switch
                v-model="domain.faq.has_universal"
                true-value="1"
                false-value="0"
              ></b-switch>
            </b-field>
          </div>

          <hr>

          <div class="module" v-if="domain.chat.is_activated === 1">
            <h3>Chat</h3>
            <b-field horizontal label="Nombre de chats" >
              <b-input
                type="text"
                v-model="domain.chat.max_chat_per_day"
                placeholder="Nombre de chats max par jour"
                required>
              </b-input>
            </b-field>
          </div>

          <hr>

          <div class="module" v-if="domain.news.is_activated === 1">
            <h3>Actualités</h3>
            <b-field horizontal label="Nombre de news">
              <b-input
                type="text"
                v-model="domain.news.news_number"
                placeholder="Nombre de news visibles sur l'accueil"
                required>
              </b-input>
            </b-field>
          </div>

          <hr>

          <div class="module">
            <h3>Autres</h3>
            <b-field horizontal label="Hôtes">
              <b-input
                type="text"
                v-model="pattern"
                placeholder="Hôte de domaine">
              </b-input>
              <b-button class="button is-primary" type="button" @click.prevent="addHostname">Ajouter</b-button>
            </b-field>
            <b-field horizontal>
              <ul>
                <li v-for="(hostname, i) in domain.hostnames" :key="i">{{ hostname.pattern }}</li>
              </ul>
            </b-field>
          </div>
          <b-field horizontal class="actions">
            <div class="is-pulled-right">
              <b-button class="button is-danger" type="button" @click.prevent="deleteDomain">Supprimer</b-button>
              <b-button class="button is-primary is-pulled-right" @click.prevent="updateDomain">Sauvegarder</b-button>
            </div>
          </b-field>
        </div>
      </section>
    </form>
  </div>
</template>

<script>
import { http } from '@/services/api.service'

export default {
  name: 'app-domain',
  data () {
    return {
      domain: null,
      pattern: '',
      logoUrl: null
    }
  },
  mounted () {
    this.getDomain()
  },
  methods: {
    getDomain () {
      http.get(`/domain/${this.$route.params.uuid}`).then(res => {
        this.domain = res.data
        if (this.domain.has_logo) {
          this.logoUrl = `${process.env.VUE_APP_STORAGE_URL}/domains/${this.domain.uuid}.png`
        }
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à récupérer les informations concernant ce domaine',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    updateDomain () {
      http.put(`/domain/${this.domain.uuid}`, { ...this.domain }).then(res => {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Le domaine a été mis à jour avec succès !',
          position: 'is-bottom-left',
          type: 'is-success',
          hasIcon: true
        })
        this.$router.push({ path: '/app/domains' })
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à mettre à jour ce domaine',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    deleteDomain () {
      this.$buefy.dialog.confirm({
        title: 'Suppression',
        message: `Êtes vous sur de vouloir supprimer ce domaine ?`,
        cancelText: 'Annuler',
        confirmText: 'Confirmer',
        type: 'is-danger',
        onConfirm: () => {
          http.delete(`/domain/${this.domain.uuid}`).then(res => {
            this.$buefy.notification.open({
              duration: 3000,
              message: 'Le domaine a été supprimé avec succès !',
              position: 'is-bottom-left',
              type: 'is-success',
              hasIcon: true
            })
            this.$router.push({ path: '/app/domains' })
          }).catch(err => {
            console.log(err)
            this.$buefy.notification.open({
              duration: 3000,
              message: 'Nos serveurs rencontrent des difficultés à supprimer ce domaine',
              position: 'is-bottom-left',
              type: 'is-warning',
              hasIcon: true,
              icon: 'server'
            })
          })
        }
      })
    },
    addHostname () {
      http.post(`domain/${this.domain.uuid}/hostname`, { pattern: this.pattern }).then(res => {
        this.domain.hostnames = res.data
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.pattern = ''
      })
    },
    addLogo (file) {
      const formData = new FormData()
      formData.append('logo', file)
      http.post(`/domain/${this.domain.uuid}/logo`, formData).then(res => {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Le logo du domaine a bien été uploadé',
          position: 'is-bottom-left',
          type: 'is-success',
          hasIcon: true
        })
        this.logoUrl = `${process.env.VUE_APP_STORAGE_URL}/domains/${this.domain.uuid}.png?${Math.random()}`
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à mettre à jour cette article',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.module {
  h3 {
    font-size: 30px;
    margin: 10px 0;
    text-align: center;
    text-transform: uppercase;
  }
}
</style>
